<template>
    <div style="display: contents">
        <div v-if="!endpoint && !notFound">
            <v-skeleton-loader
                type="article"
            ></v-skeleton-loader>
            <v-skeleton-loader
                type="article"
            ></v-skeleton-loader>
        </div>
        <v-container v-else-if="notFound">
            <v-container fill-height fluid>
                <v-row align="center" class="pt-10">
                    <v-col>
                        <div class="text-h4 font-weight-light text-center">
                            Endpoint Not Found<br>
                            <img :src="`${baseUrl}/static/img/notfound.svg`" width="500" />
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <div v-else>
            <v-row no-gutters>
                <v-col>
                    <div class="text-h4 mb-6">
                        <v-icon left x-large v-text="endpointIcon[endpoint.endpoint_type]" /> {{ endpoint.name }}
                    </div>
                </v-col>
                <v-col cols="4" class="text-right">
                    <v-chip
                        :color="endpointStatus[endpoint.endpoint_status]"
                        outlined
                        label
                        class="overline"
                        v-text="endpoint.endpoint_status"
                    />
                </v-col>
            </v-row>
            
            <!-- ENDPOINT DETAILS -->
            <div class="text-h5 grey--text text--darken-2 font-weight-light">Endpoint Details</div>
            <v-divider />
            <template v-if="endpoint.last_checkin">
                <v-card class="mt-4" rounded outlined>
                    <v-card-title><v-icon left>mdi-update</v-icon>Last Checkin</v-card-title>
                    <v-card-text>
                        <span :title="endpoint.last_checkin">{{ getDateDistanceIso(endpoint.last_checkin) }} ago</span>
                    </v-card-text>
                </v-card>
            </template>
            <SnapshotDetail :snapshot="endpoint.last_snapshot" />

            <!-- CARTOGRAPHY -->
            <template v-if="endpoint.floor_plan_annotation_item_snapshot">
                <div class="text-h5 grey--text text--darken-2 font-weight-light mt-6">Cartography</div>
                <v-divider class="mb-2" />
                <div class="overline">Last Annotation</div>
                <router-link :to="{name: 'cartographyFloorPlanAnnotation', params: {floorPlanId: endpoint.floor_plan_annotation_item_snapshot.item.annotation.floor_plan, annotationId: endpoint.floor_plan_annotation_item_snapshot.item.annotation.pk}}">
                    {{ endpoint.floor_plan_annotation_item_snapshot.item.annotation.__str__ }}
                </router-link>
                
                <v-card class="mt-4 pa-1">
                    <v-img style="cursor: pointer" @click.stop="showCartDialog" :src="endpoint.floor_plan_annotation_item_snapshot.image" height="500" contain />
                </v-card>
                <v-dialog
                    v-model="config.cartDialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                    eager
                >
                    <v-toolbar
                        dark
                        color="grey darken-3"
                    >
                        <v-btn
                            icon
                            dark
                            @click="config.cartDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <div id="pspdfkit" style="width: 100%; height: 100%" />
                </v-dialog>
            </template>

            <!-- SNAPSHOTS -->
            <template v-if="endpoint.snapshot_count && endpoint.snapshot_count > 1">
                
                    <div class="mt-6">
                        <span class="text-h5 grey--text text--darken-2 font-weight-light">Snapshots</span>
                        <ul class="legend">
                            <li class="grey--text text--darken-2 font-weight-light text-h8"><span class="changes"></span>Changes</li>
                        </ul>
                    </div>
                    
                
                <v-divider class="mb-4" />
                <v-expansion-panels v-model="config.snapshotPanel" class="mb-10">
                    <v-expansion-panel v-if="!snapshots">
                        <v-expansion-panel-header v-if="!config.loadingSnapshots" @click.once="loadSnapshots">
                            Load {{ endpoint.snapshot_count }} Snapshots
                        </v-expansion-panel-header>
                        <v-expansion-panel-header v-else>
                            Loading Snapshots...
                            <template v-slot:actions>
                                <v-progress-circular color="primary" indeterminate />
                            </template>
                        </v-expansion-panel-header>
                    </v-expansion-panel>
                    <v-expansion-panel v-else v-for="(snapshot, i) in snapshots" :key="i">
                        <v-expansion-panel-header>
                            Snapshot {{ endpoint.snapshot_count - i }}
                            <template v-slot:actions>
                                <div class="d-flex align-center">
                                <v-chip v-if="i === 0" class="mr-2" small outlined>Latest</v-chip>{{ getDateFormat(snapshot.created, 'PPpp') }}
                                </div>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <SnapshotDetail :snapshot="snapshot" :highlight="getChanges(snapshot)" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import PSPDFKit from 'pspdfkit';
import { ref, reactive } from '@vue/composition-api';

import dateTimeHelper from '@/composables/common/dateTime';
import SnapshotDetail from '@/components/it/inventory/SnapshotDetail';

export default {
    name: 'EndpointDetail',
    components: {
        SnapshotDetail
    },
    props: {
        endpoint: {
            type: Object,
            default() {
                return {}
            }
        },
        notFound: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {

        const snapshots = ref(null);

        const config = reactive({
            loadingSnapshots: false,
            snapshotPanel: null,
            cartDialog: false
        });


        const {
            getDateFormat,
            getDateDistanceIso
        } = dateTimeHelper();


        const loadSnapshots = async () => {
            config.loadingSnapshots = true;
            const response = await axios.get(`/inventory/load_endpoint_snapshots/${props.endpoint.pk}/`);
            snapshots.value = response.data;
            config.snapshotPanel = [0];
            config.loadingSnapshots = false;
        };

        const getChanges = (snapshot) => {
            let ret = []
            if (snapshot.changes.added) {
                let add = snapshot.changes.added.map(x => x.field.toLowerCase())
                ret = ret.concat(add) 
            }
            if (snapshot.changes.changed) {
                let change = snapshot.changes.changed.map(x => x.field.toLowerCase())
                ret = ret.concat(change)
            }
            return ret;
        };


        const showCartDialog = async () => {
            config.cartDialog = true;
            PSPDFKit.unload('#pspdfkit'); // unload any existing containers
            await PSPDFKit.load({
                locale: 'en',
                baseUrl: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PSPDFKIT_BASEURL : process.env.VUE_APP_PSPDFKIT_BASEURL_DEV,
                container: "#pspdfkit",
                toolbarItems: [{"type": "pan"}, {"type": "zoom-out"}, {"type": "zoom-in"}, {"type": "zoom-mode"}, {"type":"print"}],
                styleSheets: [`${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASEURL : process.env.VUE_APP_BASEURL_DEV}/static/css/pspdfkit.css`],
                document: props.endpoint.floor_plan_annotation_item_snapshot.pdf,
                licenseKey: `${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PSPDFKIT_LICENSE_KEY : process.env.VUE_APP_PSPDFKIT_LICENSE_KEY_DEV}`,
            });
        };


        return {
            config,
            getDateFormat,
            getDateDistanceIso,
            snapshots,
            loadSnapshots,
            getChanges,
            showCartDialog
        }
    }
};
</script>
<style>
.legend{ list-style: none; display: block; padding-left: 0px !important; float: right; margin-top:4px;}
.legend li { float: left; margin-right: 10px; }
.legend span { border: 1px solid #ccc; float: left; width: 20px; height: 20px; margin-right: 10px; }
.legend .changes { background-color: rgba(255, 255, 0, 0.5); }
</style>