<template>
    <div style="display: contents">

        <!-- ASSIGNMENT -->
        <template v-if="snapshot.division || snapshot.location || snapshot.primary_user">
            <v-card class="mt-4" rounded outlined>
                <v-card-title><v-icon left>mdi-clipboard-account-outline</v-icon>Assignment</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col v-if="snapshot.division" cols="12" sm="4">
                            <div class="overline">Division</div>
                            <span :class="checkChanges('division')">{{ snapshot.division }}</span>
                        </v-col>
                        <v-col v-if="snapshot.location" cols="12" sm="4">
                            <div class="overline">Location</div>
                            <span :class="checkChanges('location')">{{ snapshot.location }}</span>
                        </v-col>
                        <v-col v-if="snapshot.primary_user" cols="12" sm="4">
                            <div class="overline">Primary User</div>
                            <span :class="checkChanges('primary user')">{{ snapshot.primary_user.name }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </template>


        <!-- HARDWARE -->
        <v-card class="mt-4" rounded outlined>
            <v-card-title><v-icon left>mdi-cog</v-icon>Hardware</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col v-if="snapshot.make" cols="12" sm="4" md="3">
                        <div class="overline">Make / Model</div>
                        <span :class="checkChanges('make') || checkChanges('model')">{{ snapshot.make }} {{ snapshot.model }}</span>
                    </v-col>
                    <v-col v-if="snapshot.serial" cols="12" sm="2" md="3">
                        <div class="overline">Serial</div>
                        <span :class="checkChanges('serial')">{{ snapshot.serial }}</span>
                    </v-col>
                    <v-col v-if="snapshot.imei" cols="12" sm="4" md="3">
                        <div class="overline">IMEI</div>
                        <span :class="checkChanges('imei')">{{ snapshot.imei }}</span>
                    </v-col>
                    <v-col v-if="snapshot.cpu" cols="12" sm="4" md="3">
                        <div class="overline">CPU</div>
                        <span :class="checkChanges('cpu') || checkChanges('os version')">{{ snapshot.cpu }} {{ snapshot.os_version }}</span>
                    </v-col>
                    <v-col v-if="snapshot.memory" cols="12" sm="2" md="3">
                        <div class="overline">Memory</div>
                        <span :class="checkChanges('memory')">{{ snapshot.memory }}</span>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>


        <!-- SOFTWARE -->
        <template v-if="snapshot.os_name">
            <v-card class="mt-4" rounded outlined>
                <v-card-title><v-icon left>mdi-application</v-icon>Software</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col v-if="snapshot.os_name" cols="12" sm="6">
                            <div class="overline">Operating System</div>
                            <span :class="checkChanges('os name') || checkChanges('os version')">{{ snapshot.os_name }} {{ snapshot.os_version }}</span>
                        </v-col>
                        <v-col v-if="snapshot.bios_ver" cols="12" sm="3">
                            <div class="overline">BIOS</div>
                            <span :class="checkChanges('bios version') || checkChanges('bios date')">{{ snapshot.bios_ver }} {{ snapshot.bios_date }}</span>
                        </v-col>
                        <v-col v-if="snapshot.encryption" cols="12" sm="3">
                            <div class="overline">Encryption</div>
                            <span :class="checkChanges('encryption')">{{ snapshot.encryption }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </template>


        <!-- NETWORKING -->
        <template v-if="snapshot.ip_address || snapshot.mac_wired || snapshot.mac_wireless || snapshot.port_number">
            <v-card class="mt-4" rounded outlined>
                <v-card-title><v-icon left>mdi-lan</v-icon>Networking</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col v-if="snapshot.ip_address" cols="12" sm="4">
                            <div class="overline">IP Address</div>
                            <span :class="checkChanges('ip address')">{{ snapshot.ip_address }}</span>
                        </v-col>
                        <v-col v-if="snapshot.mac_wired" cols="12" sm="4">
                            <div class="overline">MAC Address</div>
                            <span :class="checkChanges('mac (wired)')">{{ snapshot.mac_wired }}</span>
                        </v-col>
                        <v-col v-if="snapshot.mac_wireless" cols="12" sm="4">
                            <div class="overline">MAC Address (Wireless)</div>
                            <span :class="checkChanges('mac (wired)')">{{ snapshot.mac_wireless }}</span>
                        </v-col>
                        <v-col v-if="snapshot.port_number" cols="12" sm="4">
                            <div class="overline">Port Number</div>
                            <span :class="checkChanges('jack / port #')"></span>{{ snapshot.port_number }}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </template>


        <!-- PRINTERS -->
        <template v-if="printers">
            <v-card class="mt-4" rounded outlined>
                <v-card-title><v-icon left>mdi-printer</v-icon>Printers</v-card-title>
                <v-card-text>
                    <v-row v-for="(printer, i) in printers.items" :key="i" class="mt-0">
                        <v-col>
                            <div class="overline">Name</div>
                            <span :class="checkChanges(`printer ${i.replace('printer_', '')} name`)">{{ printer.name }}</span>
                        </v-col>
                        <v-col>
                            <div class="overline">Port</div>
                            <span :class="checkChanges(`printer ${i.replace('printer_', '')} port`)">{{ printer.port || 'None' }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="printers.default_printer_queue" class="mt-0">
                        <v-col v-if="printers.default_printer_queue" cols="12" sm="6">
                            <div class="overline">Default Printer Queue</div>
                            <EndpointDialog
                                :endpoint="{name: printers.default_printer_queue.replace('TAG', 'SRG')}"
                            />
                            <!--
                            <router-link :to="{name: 'it/inventory/endpoint', params: {endpointId: printers.default_printer_queue.replace('TAG', 'SRG')}}">
                                {{ printers.default_printer_queue.replace('TAG', 'SRG') }}
                            </router-link>
                            -->
                        </v-col>
                        <v-col v-if="printers.alt_printer_queue" cols="12" sm="6">
                            <div class="overline">Alternate Printer Queue</div>
                            <EndpointDialog
                                :endpoint="{name: printers.alt_printer_queue.replace('TAG', 'SRG')}"
                            />
                            <!--
                            <router-link :to="{name: 'it/inventory/endpoint', params: {endpointId: printers.alt_printer_queue.replace('TAG', 'SRG')}}">
                                {{ printers.alt_printer_queue.replace('TAG', 'SRG') }}
                            </router-link>
                            -->
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </template>

        <!-- MONITORS -->
        <template v-if="monitors">
            <v-card class="mt-4" rounded outlined>
                <v-card-title><v-icon left>mdi-monitor</v-icon>Monitors</v-card-title>
                <v-card-text>
                    <v-row v-for="(monitor, i) in monitors.items" :key="i">
                        <v-col v-if="monitor.mfg">
                            <div class="overline">Manufacturer</div>
                            <span :class="checkChanges(`monitor ${i.replace('monitor_','')} caption`)">{{ monitor.mfg }}</span>
                        </v-col>
                        <v-col v-if="monitor.size">
                            <div class="overline">Size</div>
                            <span :class="checkChanges(`monitor ${i.replace('monitor_','')} size`)">{{ monitor.size }}</span>
                        </v-col>
                        <v-col v-if="monitor.serial">
                            <div class="overline">Serial</div>
                            <span :class="checkChanges(`monitor ${i.replace('monitor_','')} serial`)">{{ monitor.serial }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </template>
    </div>
</template>

<script>
import EndpointDialog from '@/components/it/inventory/EndpointDialog';

import { computed } from '@vue/composition-api';
export default {
    name: 'SnapshotDetail',
    components: {
        EndpointDialog
    },
    props: {
        snapshot: {
            type: Object,
            default() {
                return {}
            }
        },
        highlight: {
            type: Array,
            default() {
                return []
            }
        }
    },
    setup(props){
        const printers = computed(() => {
            if (props.snapshot.printers) {
                return normalizeByPrefix(props.snapshot.printers, 'printer_');
            }
            return null;
        });

        const monitors = computed(() => {
            if (props.snapshot.monitors) {
                return normalizeByPrefix(props.snapshot.monitors, 'monitor_');
            }
            return null;
        });

        const normalizeByPrefix = (obj, prefix) => {
            let allItems = {items: {}};
            for (const [key, value] of Object.entries(obj)) {
                if (!key.startsWith(prefix)) {
                    allItems[key] = value;
                    continue;
                }

                let itemKey = key.split('_')
                itemKey = `${itemKey[0]}_${itemKey[1]}`;

                if (!allItems.items[itemKey]) allItems.items[itemKey] = {}
                allItems.items[itemKey][key.replace(`${itemKey}_`, '')] = value;
            }
            return allItems;
        }

        const checkChanges = (field) => {
            if (props.highlight.length && props.highlight.includes(field)){
                return 'hl-row';
            }
            return null;
        }

        return {
            printers,
            monitors,
            normalizeByPrefix,
            checkChanges
        }
    }
}
</script>
<style>
.hl-row { background-color: rgba(255, 255, 0, 0.5); }
.legend{ list-style: none; display: inline-block; padding-left: 0px !important; }
.legend li { float: left; margin-right: 10px; }
.legend span { border: 1px solid #ccc; float: left; width: 20px; height: 20px; margin: 2px; }
.legend .changes { background-color: rgba(255, 255, 0, 0.5); }
</style>