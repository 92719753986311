import { formatDistanceToNow, fromUnixTime, parseISO, format } from 'date-fns';

export default function dateTimeHelper() {

    const getDateDistanceUnix = (dt) => formatDistanceToNow(fromUnixTime(dt));

    const getDateDistanceIso = (dt, opt={}) => {
        return formatDistanceToNow(parseISO(dt), opt);
    };

    const getDateFormat = (dt, fmt='PPpp') => format(parseISO(dt), fmt);

    return {
        getDateDistanceIso,
        getDateDistanceUnix,
        parseISO,
        getDateFormat,
        format
    }
}